export const AccountDropdown = () => import('../../components/AccountDropdown.vue' /* webpackChunkName: "components/account-dropdown" */).then(c => wrapFunctional(c.default || c))
export const AccountMenuItem = () => import('../../components/AccountMenuItem.vue' /* webpackChunkName: "components/account-menu-item" */).then(c => wrapFunctional(c.default || c))
export const AccountProfilePicture = () => import('../../components/AccountProfilePicture.vue' /* webpackChunkName: "components/account-profile-picture" */).then(c => wrapFunctional(c.default || c))
export const BookingCard = () => import('../../components/BookingCard.vue' /* webpackChunkName: "components/booking-card" */).then(c => wrapFunctional(c.default || c))
export const Btn = () => import('../../components/Btn.vue' /* webpackChunkName: "components/btn" */).then(c => wrapFunctional(c.default || c))
export const BusinessPopup = () => import('../../components/BusinessPopup.vue' /* webpackChunkName: "components/business-popup" */).then(c => wrapFunctional(c.default || c))
export const BusinessReviews = () => import('../../components/BusinessReviews.vue' /* webpackChunkName: "components/business-reviews" */).then(c => wrapFunctional(c.default || c))
export const BusinessSchedule = () => import('../../components/BusinessSchedule.vue' /* webpackChunkName: "components/business-schedule" */).then(c => wrapFunctional(c.default || c))
export const BusinessServices = () => import('../../components/BusinessServices.vue' /* webpackChunkName: "components/business-services" */).then(c => wrapFunctional(c.default || c))
export const CalendarPicker = () => import('../../components/CalendarPicker.vue' /* webpackChunkName: "components/calendar-picker" */).then(c => wrapFunctional(c.default || c))
export const CardSlider = () => import('../../components/CardSlider.vue' /* webpackChunkName: "components/card-slider" */).then(c => wrapFunctional(c.default || c))
export const CardsSlider = () => import('../../components/CardsSlider.vue' /* webpackChunkName: "components/cards-slider" */).then(c => wrapFunctional(c.default || c))
export const CitySelect = () => import('../../components/CitySelect.vue' /* webpackChunkName: "components/city-select" */).then(c => wrapFunctional(c.default || c))
export const ExpandableSection = () => import('../../components/ExpandableSection.vue' /* webpackChunkName: "components/expandable-section" */).then(c => wrapFunctional(c.default || c))
export const FileInput = () => import('../../components/FileInput.vue' /* webpackChunkName: "components/file-input" */).then(c => wrapFunctional(c.default || c))
export const FormBtn = () => import('../../components/FormBtn.vue' /* webpackChunkName: "components/form-btn" */).then(c => wrapFunctional(c.default || c))
export const FormCheckbox = () => import('../../components/FormCheckbox.vue' /* webpackChunkName: "components/form-checkbox" */).then(c => wrapFunctional(c.default || c))
export const FormDate = () => import('../../components/FormDate.vue' /* webpackChunkName: "components/form-date" */).then(c => wrapFunctional(c.default || c))
export const FormFile = () => import('../../components/FormFile.vue' /* webpackChunkName: "components/form-file" */).then(c => wrapFunctional(c.default || c))
export const FormGroup = () => import('../../components/FormGroup.vue' /* webpackChunkName: "components/form-group" */).then(c => wrapFunctional(c.default || c))
export const FormInput = () => import('../../components/FormInput.vue' /* webpackChunkName: "components/form-input" */).then(c => wrapFunctional(c.default || c))
export const FormInputPassword = () => import('../../components/FormInputPassword.vue' /* webpackChunkName: "components/form-input-password" */).then(c => wrapFunctional(c.default || c))
export const FormRadio = () => import('../../components/FormRadio.vue' /* webpackChunkName: "components/form-radio" */).then(c => wrapFunctional(c.default || c))
export const FormSelect = () => import('../../components/FormSelect.vue' /* webpackChunkName: "components/form-select" */).then(c => wrapFunctional(c.default || c))
export const FormTextarea = () => import('../../components/FormTextarea.vue' /* webpackChunkName: "components/form-textarea" */).then(c => wrapFunctional(c.default || c))
export const LanguageDropdown = () => import('../../components/LanguageDropdown.vue' /* webpackChunkName: "components/language-dropdown" */).then(c => wrapFunctional(c.default || c))
export const MapPosition = () => import('../../components/MapPosition.vue' /* webpackChunkName: "components/map-position" */).then(c => wrapFunctional(c.default || c))
export const MapView = () => import('../../components/MapView.vue' /* webpackChunkName: "components/map-view" */).then(c => wrapFunctional(c.default || c))
export const MapViewMobile = () => import('../../components/MapViewMobile.vue' /* webpackChunkName: "components/map-view-mobile" */).then(c => wrapFunctional(c.default || c))
export const PasswordValidation = () => import('../../components/PasswordValidation.vue' /* webpackChunkName: "components/password-validation" */).then(c => wrapFunctional(c.default || c))
export const PaymentCard = () => import('../../components/PaymentCard.vue' /* webpackChunkName: "components/payment-card" */).then(c => wrapFunctional(c.default || c))
export const PopularBusinesses = () => import('../../components/PopularBusinesses.vue' /* webpackChunkName: "components/popular-businesses" */).then(c => wrapFunctional(c.default || c))
export const ProfessionalsList = () => import('../../components/ProfessionalsList.vue' /* webpackChunkName: "components/professionals-list" */).then(c => wrapFunctional(c.default || c))
export const Review = () => import('../../components/Review.vue' /* webpackChunkName: "components/review" */).then(c => wrapFunctional(c.default || c))
export const ReviewModal = () => import('../../components/ReviewModal.vue' /* webpackChunkName: "components/review-modal" */).then(c => wrapFunctional(c.default || c))
export const ServiceCategorySelect = () => import('../../components/ServiceCategorySelect.vue' /* webpackChunkName: "components/service-category-select" */).then(c => wrapFunctional(c.default || c))
export const ServiceSelect = () => import('../../components/ServiceSelect.vue' /* webpackChunkName: "components/service-select" */).then(c => wrapFunctional(c.default || c))
export const ServicesFilter = () => import('../../components/ServicesFilter.vue' /* webpackChunkName: "components/services-filter" */).then(c => wrapFunctional(c.default || c))
export const Shimmer = () => import('../../components/Shimmer.vue' /* webpackChunkName: "components/shimmer" */).then(c => wrapFunctional(c.default || c))
export const SpecialOffers = () => import('../../components/SpecialOffers.vue' /* webpackChunkName: "components/special-offers" */).then(c => wrapFunctional(c.default || c))
export const Stars = () => import('../../components/Stars.vue' /* webpackChunkName: "components/stars" */).then(c => wrapFunctional(c.default || c))
export const Subscribe = () => import('../../components/Subscribe.vue' /* webpackChunkName: "components/subscribe" */).then(c => wrapFunctional(c.default || c))
export const Tab = () => import('../../components/Tab.vue' /* webpackChunkName: "components/tab" */).then(c => wrapFunctional(c.default || c))
export const Tabs = () => import('../../components/Tabs.vue' /* webpackChunkName: "components/tabs" */).then(c => wrapFunctional(c.default || c))
export const TermsAcceptanceModal = () => import('../../components/TermsAcceptanceModal.vue' /* webpackChunkName: "components/terms-acceptance-modal" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
